@import "../global";

.video {
  width: 100%;
  height: 400px;
  border: 2px solid $golden;
  border-radius: 10px;
}

@media screen and (max-width: 796px) {
  .video {
    height: 250px;
  }
}
