@import "../../shared/global";

.project {
  width: 500px;
  height: 300px;
  background-color: $black;
  color: $golden;
  margin: 20px;
  cursor: pointer;
  margin-bottom: 20px;

  > h3 {
    padding: 5px 10px;
    text-align: left;
    margin: 0px;
  }

  img {
    width: 500px;
  }
}
@media (max-width: 1156px) {
  .project {
    width: 95%;
    height: auto;
    margin: 15px auto;
    img {
      width: 100%;
    }
  }
}
// img size 1870 * 1050
// 935 *525
