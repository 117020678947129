@import "../../shared/global";

.post {
  margin-bottom: 10px;
  color: $black;
  background-color: $dirty;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
  text-align: justify;
  border: 2px solid $golden;

  h2 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.post-header {
  display: flex;
  justify-content: space-between;
  img {
    max-width: 48px;
    max-height: 48px;
  }
}
.dots {
  margin-top: 0px;
  font-size: 2em;
}

.hide {
  display: none;
}
kbd {
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #b4b4b4;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2),
    0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
  color: #333;
  font-size: 1.2em;
  font-weight: 700;
  line-height: 1;

  padding: 5px 8px;
  text-align: center;
}

@media screen and (max-width: 796px) {
  .post {
    h2 {
      text-align: center;
      font-size: 1.2em;
    }
    .post-header {
      img {
        max-width: 32px;
        max-height: 32px;
      }
    }
    .dots {
      text-align: center;
    }
  }
}
