@import "../../shared/global";

.home {
  color: $golden;
  p {
    text-align: justify;
    font-size: 1.2em;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 70%;
  margin: 0 auto;
  text-align: center;
}

.col-1 {
  grid-column: 1/6;
}
.col-2 {
  grid-column: 6/8;
  img {
    width: 120px;
    position: relative;
    top: -60px;
    left: 0;
  }
}
.col-3 {
  grid-column: 8/13;
}

.home-about {
  grid-area: about;
  margin: 0 auto;
  p {
    text-align: center;
  }
  h3 {
    font-size: 1.7em;
    text-align: center;
  }
}

@media screen and (max-width: 796px) {
  .grid {
    width: 90%;
    grid-column: 1 / span 12;

    p {
      font-size: 1.2em;
    }
  }
  .col-1 {
    grid-column: 1 / span 12;
    grid-row: 2;
  }
  .col-2 {
    grid-column: 1 / span 12;
    grid-row: 1;
    img {
      width: 100px;
      top: 0px;
    }
  }
  .col-3 {
    grid-column: 1 / span 12;
    grid-row: 3;
  }
}
