@import "../../shared/global";

.headers {
  margin-bottom: 40px;
  .topnav {
    overflow: hidden;
    background-color: #333;
    text-align: center;

    a,
    button {
      font-family: inherit;
      display: inline-block;
      color: $golden;
      text-align: center;
      padding: 12px 0px;
      text-decoration: none;
      font-size: 1.5em;
      font-weight: 600;
      min-width: 150px;
      background-color: #333;
      border: none;
      outline: none;
      cursor: pointer;
    }

    a:hover,
    button:hover {
      background-color: $black;
      color: $golden;
    }
    a.active {
      background-color: $golden;
      color: $dirty;
    }
  }

  .burger {
    display: none;
    cursor: pointer;

    .bar1,
    .bar2,
    .bar3 {
      width: 35px;
      height: 5px;
      background-color: $golden;
      margin: 6px 0;
      transition: 0.4s;
    }
  }
}

@media screen and (max-width: 796px) {
  .headers {
    margin-bottom: 20px;

    .topnav {
      a.links {
        display: block;
        float: left;
        min-width: 110px;
        font-size: 1.3em;
      }
      button {
        font-size: 1.3em;
      }
    }
  }

  .topnav a.links:not(:first-child) {
    display: none;
  }
  .topnav .burger {
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
  }

  .bar1.change {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
  }

  .bar2.change {
    opacity: 0;
  }

  .bar3.change {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
  }
}

@media screen and (max-width: 796px) {
  .topnav.responsive {
    position: relative;
  }
  .topnav.responsive .burger {
    position: absolute;
    right: 10px;
    top: 10px;
    .bar1,
    .bar2,
    .bar3 {
      background-color: $dirty;
    }
  }
  .topnav.responsive a.links {
    float: none;
    display: block;
    text-align: left;
    padding-left: 15px;
  }
}

@media print {
  .headers {
    display: none !important;
  }
}
