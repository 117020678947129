.my-footer {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  justify-content: center;
  grid-gap: 20px;
  margin: 30px;
  div {
    display: inline-block;
  }
  img {
    height: 30px;
    max-width: 40px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 796px) {
  .my-footer {
    grid-template-columns: auto auto auto;
  }
}

@media print {
  .my-footer {
    display: none !important;
  }
}
