@import "../../shared/global";

.blog {
  color: $golden;
  width: 60%;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (max-width: 796px) {
  .blog {
    width: 95%;
  }
}
