.cv-container {
  background-color: #fff;
  width: 100%;
  height: 100%;
}
.cv {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  background-color: #fff;
  color: #000;
  p {
    padding: 0px 20px;
  }
  margin: auto;
  h4 {
    margin-top: 20px;
    padding-left: 17px;
    font-size: 1.4em;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .first-page {
    width: 794px;
    max-height: 1123px;
    margin: 0 auto;
    .header {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      margin: 0 auto;
      border-right: 2px solid grey;

      .empty {
        grid-column: 1/5;
        grid-row: 1;
        background-color: rgb(236, 236, 236);
      }
      .author {
        grid-column: 4/13;

        background-color: #fff;
        grid-row: 1;
        z-index: 2;
        margin-top: 30px;

        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          border: 2px solid grey;
          height: 150px;
          width: 70%;
          z-index: 3;
          background-color: #fff;
          h1 {
            line-height: 35px;
            letter-spacing: 1.3px;
            font-size: 45px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 0px;
            margin-top: 5px;
          }
          h3 {
            margin-bottom: 0;
            text-align: center;
            letter-spacing: 5.7px;
            margin-top: 10px;
            font-weight: bold;
            color: rgb(188, 189, 185);
          }
        }
      }
    }
    .main {
      height: 100%;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      margin-bottom: 30px;
      .info {
        grid-column: 1/5;
        padding-left: 50px;
        background-color: rgb(236, 236, 236);
        .light-text {
          color: rgb(141, 141, 141);
          font-size: 0.8em;
          margin-bottom: -5px;
          padding-top: 0px;
          margin-top: 7px;
        }
        .dark-text {
          color: rgb(85, 84, 84);
          padding-bottom: 0px;
          font-weight: bold;
          margin-bottom: 0px;
          margin-top: 0px;
        }
        a:link,
        a:visited {
          color: rgb(85, 84, 84);
          text-decoration: none;
        }
        .list-skills {
          p {
            margin: 0px;
            color: rgb(85, 84, 84);
            padding-bottom: 0px;
            font-weight: bold;
            margin-bottom: 0px;
            margin-top: 0px;
          }
        }
      }
      .about {
        grid-column: 5/13;
        padding-right: 50px;
        border-right: 2px solid grey;
        background-color: #fff;
        p {
          text-align: justify;
          margin-top: 0px;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.projects {
  margin: 0;
  padding: 0 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  background-color: #fff;
  color: #000;
  p {
    padding: 0px 20px;
  }
  width: 794px;
  margin: 30px auto;
  padding: 0px 0px;

  .single-project {
    display: grid;
    grid-template-columns: 120px auto 50px;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;

    h5 {
      font-size: 1.1em;
      font-weight: bold;
      margin: 10px 0px;
    }
    a {
      text-decoration: none;
    }
  }
  .table {
    font-weight: bold;
    border: 2px solid grey;
    padding: 20px 10px;
  }
  .description {
    padding: 0 20px;
    p:first-child {
      font-weight: bold;
    }
  }

  p {
    text-align: justify;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px;
  }
  span {
    margin-left: 20px;
  }
  // .single-project {
  //   p:nth-of-type(2) {
  //     padding-top: 0px;
  //   }
  // }
  a:visited {
    color: blue;
  }
}

.github {
  text-align: center;
}

.page-break {
  page-break-after: always;
}

@media screen and (max-width: 796px) {
  .cv {
    .first-page {
      max-height: none;
      height: auto;
      width: 100%;
      .header {
        .empty {
          display: none;
        }
        .author {
          grid-column: 1/13;
          border-right: none;
          .content {
            width: 100%;
            border: none;
            h1 {
              font-size: 2.5em;

              h3 {
                font-size: 1em;
              }
            }
          }
        }
      }

      .main {
        grid-column: 1 / 13;
        height: 100%;
        .info {
          grid-column: 1 / 13;
          grid-row: 1;
          padding-left: 10px;
        }
        .about {
          grid-column: 1 / 13;
          grid-row: 2;
          border-right: none;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
  .projects {
    width: 90%;
    margin-bottom: 0px;
  }
}

@media print {
  .no-print {
    display: none !important;
  }
  @page {
    size: A4 portrait;
  }
  .cv {
    width: 100%;
    .first-page {
      width: 794px;
      height: 940px;
      .header {
        border-right: none;
      }
      .main {
        .about {
          border-right: none;
        }
      }
    }
  }
  .projects {
    width: 90%;
    .single-project {
      padding: 0 10px;
    }
    .zoom {
      margin-top: 60px;
    }
  }

  //   @page {
  //     size: A4 portrait;
  //     margin-top: 1.2cm;
  //     margin-bottom: 2cm;
  //     margin-left: 2cm;
  //     margin-right: 2cm;
  //   }
}

// @media print {
//   .no-print {
//     display: none !important;
//     scale: 50%;
//   }
//   @page {
//     size: A4 portrait;
//     margin-top: 1.8cm;
//     margin-bottom: 2cm;
//     margin-left: 2cm;
//     margin-right: 2cm;
//   }
// }
