@import "../global";

.dz-dialog {
  width: 750px;
  color: $golden;
  margin: 0 auto;

  .dz-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: $black;
    border-radius: 0.3rem;
    outline: 0;
  }

  .dz-header {
    display: flex;
    // align-items: flex-start;
    justify-content: space-between;
    align-content: center;
    padding: 20px 20px;

    > h2 {
      margin: 0;
      padding-bottom: 0;
      font-size: 1.5em;
    }

    .dz-close {
      background: transparent;
      border: none;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;
      text-shadow: 0 1px 0 #fff;
      opacity: 0.5;
      color: #fff;
      cursor: pointer;
      outline: none;
      :hover,
      :active {
        color: #fff;
        opacity: 0.9;
      }
      span {
        padding: 0px;
      }
    }
  }

  .dz-body {
    background-color: $golden;
    margin: -2px 0px;
    padding: 3px 0px;

    img {
      width: 100%;

      cursor: pointer;
    }
  }

  .dz-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
    background-color: $black;

    p {
      text-align: center;
    }
    .btn-container {
      display: flex;
      align-items: center;
    }
    .button {
      font: inherit;
      padding: 0.5rem 1.5rem;
      border: 3px solid $golden;
      border-radius: 6px;
      background: #f7f1e6;
      color: $golden;
      cursor: pointer;
      margin-right: 0px;
      text-decoration: none;
      outline: none;
      width: 115px;
    }
    .green {
      background-color: $green;
      &:hover,
      &:active {
        background: $golden;
        color: $black;
        border-color: $green;
      }
    }

    .red {
      background-color: $red;
      &:hover,
      &:active {
        background: $golden;
        color: $black;
        border-color: $red;
      }
    }
    .github {
      width: 48px;
      height: 48px;
      margin: 0px 20px;
    }
  }
}

.cover {
  background-color: rgba(243, 236, 236, 0.596);
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  display: flex;
  align-items: center;
}
@media (max-width: 796px) {
  .dz-dialog {
    max-width: 95%;
    .dz-content {
      .dz-header {
        h2 {
          font-size: 1.2em;
        }
      }
    }
    .dz-footer {
      .button {
        width: 64px;
        font-size: 0.9em;
        padding: 5px 10px;
      }
      .github {
        margin-top: 6px;
        width: 36px;
        height: 36px;
      }
      p {
        font-size: 0.9em;
      }
    }
  }
}
